import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = [ "id", "position", "templateid" ];

  connect() {
    StimulusReflex.register(this);
  }

  disconnect () {

  }

  skip(event) {
    console.log("SKIP");
    event.preventDefault()
    this.stimulate('Workflow#skip',
      this.idTarget.value,
      this.positionTarget.value,
      this.templateidTarget.value
    );
  }


}
