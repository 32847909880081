import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  //static targets = [ "id" ];

  connect() {
    StimulusReflex.register(this);
     $(document).on('rateable_controller.rated', function(event, el, rating) {
       this.rated(event, el, rating)
     }.bind(this))
  }

  rated(event, el, rating) {
    event.preventDefault()
    console.log(el.data())
    this.stimulate('Document#rate',
      el.data('resourceType'), el.data('resourceId'), rating
    );

  }
}
