import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = [ "id", "input", "oldstring", "newstring", "replace" ];


  connect() {
    StimulusReflex.register(this);
    const Inline = Quill.import("blots/inline");

    class SearchedStringBlot extends Inline {
      static create(value) {
        let node = super.create(value);
        node.contentEditable = "false";
        return node;
      }
    }

    SearchedStringBlot.blotName = "SearchedString";
    SearchedStringBlot.className = "ql-searched-string";
    SearchedStringBlot.tagName = "div";

    // TODO: Init to fale
    //setTimeout(() => this.delayedConnect(), 1000)
  }

  delayedConnect(){
    console.log("delayedConnect")
    // search replace start
    var container = document.querySelector(".quill_container");
    this.quill = Quill.find(container);
    let totalText = this.quill.getText();
    let re = new RegExp(this.oldstringTarget.value, "gi");
    let match = re.test(totalText);
    if(match){
      console.log("match "+this.oldstringTarget.value)
    }else{
      console.log("no match "+this.oldstringTarget.value)

      this.replaceTarget.value = "false" // alpinejs conflict todo
    }
  }

  disconnect () {
  }

  search(event) {
    //event.preventDefault()
    if(this.hasOldstringTarget && this.hasReplaceTarget && this.replaceTarget.value.toString() == "true" && this.oldstringTarget.value != ""){

      this.occurrencesIndices = [];
      var SearchedStringLength = 0;
      var SearchedString = "";

      console.log('search');
      var container = document.querySelector(".quill_container");
      this.quill = Quill.find(container);

      let string = this.oldstringTarget.value

      SearchedString = string

      let totalText = this.quill.getText();
      let re = new RegExp(SearchedString, "gi");
      let match = re.test(totalText);
      if (match) {
        let indices = (this.occurrencesIndices = totalText.getIndicesOf(
          SearchedString
        ));
        let length = (SearchedStringLength =
          SearchedString.length);

        indices.forEach(index =>
          this.quill.formatText(index, length, "SearchedString", true)
        );
      }

      // this.removeStyle();
      setTimeout(() => {
        this.quill.formatText(0, this.quill.getText().length, "SearchedString", false);
      }, 500)
    }

  }

  replace(event) {
    // event.preventDefault()

    if(this.hasOldstringTarget && this.hasReplaceTarget && this.replaceTarget.value.toString() == "true" && this.oldstringTarget.value != ""){
      var container = document.querySelector(".quill_container");
      this.quill = Quill.find(container);

      this.occurrencesIndices = [];
      let currentIndex = 0;
      var SearchedStringLength = 0;
      var SearchedString = "";


      let oldString = this.oldstringTarget.value
      let newString = this.newstringTarget.value

      SearchedString = oldString

      let totalText = this.quill.getText();
      let re = new RegExp(SearchedString, "gi");
      let match = re.test(totalText);
      if (match) {
        let indices = (this.occurrencesIndices = totalText.getIndicesOf(
          SearchedString
        ));
        let length = (SearchedStringLength =
          SearchedString.length);

        indices.forEach(index =>
          this.quill.formatText(index, length, "SearchedString", true)
        );
      }

      // console.log("oldString: "+oldString);
      // console.log("newString: "+newString);
      // if (!SearchedString) return;

      // if no occurrences, then search first.
      if (!this.occurrencesIndices) this.search(oldString);
      if (!this.occurrencesIndices) return;

      let indices = this.occurrencesIndices;
      // console.log(indices);

      //let oldString = document.getElementById("search-input").value;
      //let newString = document.getElementById("replace-input").value;

      this.quill.deleteText(indices[currentIndex], oldString.length);
      this.quill.insertText(indices[currentIndex], newString);

      this.quill.formatText(
        indices[currentIndex],
        newString.length,
        "SearchedString",
        true
      );
      this.quill.scrollIntoView();

      this.oldstringTarget.value = newString
      setTimeout(() => {
        this.quill.formatText(0, this.quill.getText().length, "SearchedString", false);
      }, 500)
    }
  }



  ping () {
    console.log('PONG')
  }



}
