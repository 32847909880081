import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "id", "input", "oldstring", "newstring", "outputid", "position", "outputs" ];

  connect() {
    StimulusReflex.register(this);
    //console.log("runs js attached");
    document.removeEventListener('processrun', this.processContent)
    document.addEventListener('processrun', this.processContent, { passive: true});

  }

  disconnect () {
    document.removeEventListener('processrun', this.processContent)
  }

  search() {
    var container = document.querySelector(".quill_container");
    this.quill = Quill.find(container);

    string = this.oldstringTarget.value
    SearchedStringBlot.blotName = "SearchedString";
    SearchedStringBlot.className = "ql-searched-string";
    SearchedStringBlot.tagName = "div";

    SearchedString = string

    let totalText = this.quill.getText();
    let re = new RegExp(SearchedString, "gi");
    let match = re.test(totalText);
    if (match) {
      let indices = (occurrencesIndices = totalText.getIndicesOf(
        SearchedString
      ));
      let length = (SearchedStringLength =
        SearchedString.length);

      indices.forEach(index =>
        this.quill.formatText(index, length, "SearchedString", true)
      );
    }
  }

  replace() {
    var container = document.querySelector(".quill_container");
    this.quill = Quill.find(container);

    oldString = this.oldstringTarget.value
    newString = this.newstringTarget.value

    if (!SearchedString) return;

    // if no occurrences, then search first.
    if (!this.occurrencesIndices) this.search(oldString);
    if (!this.occurrencesIndices) return;

    let indices = this.occurrencesIndices;

    //let oldString = document.getElementById("search-input").value;
    //let newString = document.getElementById("replace-input").value;

    this.quill.deleteText(indices[currentIndex], oldString.length);
    this.quill.insertText(indices[currentIndex], newString);
    this.quill.formatText(
      indices[currentIndex],
      newString.length,
      "SearchedString",
      false
    );
    // update the occurrencesIndices.
    this.removeStyle();
  }

  removeStyle() {
    var container = document.querySelector(".quill_container");
    this.quill = Quill.find(container);

    this.quill.formatText(0, this.quill.getText().length, "SearchedString", false);
  }

  posttoapp(event){
    event.preventDefault()
    let newContent = this.inputTarget.value.trim();
    // window.parent.postMessage({content: newContent}, '*');

    if (newContent.startsWith('"') && newContent.endsWith('"')) {
        newContent = newContent.substring(1, newContent.length - 1);
    }

    window.parent.postMessage({
      origin: 'mai',
      command: 'change_text',
      data: newContent
    }, '*');

    console.log("post to app")
  }

  insert(event) {
    event.preventDefault()
    let newContent = this.inputTarget.value;

    var container = document.querySelector(".quill_container");
    this.quill = Quill.find(container);

    this.botIndex = this.quill.setSelection(this.quill.getLength(), 0);;

    if(this.quill.getSelection() != null){
      this.botIndex = this.quill.getSelection()["index"];
    }

    this.quill.insertText(this.botIndex, newContent, "user");
    this.markdownHandler();
  }

  select(event) {
    event.preventDefault()
    //let newContent = this.inputTarget.value;

    // console.log("selected "+ this.outputidTarget.value + " / position "+this.positionTarget.value + " / "+ this.inputTarget.value)
    this.stimulate('Document#selectrun',
      this.outputidTarget.value,
      this.positionTarget.value,
      this.inputTarget.value
    );

    setTimeout(() => {
      const buttons = document.querySelectorAll(".compose");
      console.log("select enable buttons");
      for (const button of buttons) {
        button.disabled = false;
        button.getElementsByClassName("compose-loading")[0].classList.add("hidden");
        button.getElementsByClassName("compose-pending")[0].classList.remove("hidden");
      }
    }, 300);
  }

  ping () {
    console.log('PONG')
  }

  processContent = event => {
    setTimeout(() => {
      const buttons = document.querySelectorAll(".compose");
      console.log("processContent enable buttons");
      for (const button of buttons) {
        button.disabled = false;
        button.getElementsByClassName("compose-loading")[0].classList.add("hidden");
        button.getElementsByClassName("compose-pending")[0].classList.remove("hidden");
      }
      if($('.notcompleted').first()[0] !== undefined){
        // $('.notcompleted').first()[0].scrollIntoView({block: "end"});
        // setTimeout(function(){
        //   console.log("scrolling to "+ $('.notcompleted').first().offset().top);
        //   $('#chat__widget--content').stop().animate({
        //       scrollTop: $('.notcompleted').first().offset().top
        //   }, 'slow', 'linear');
        // }, 2000);
      }
    }, 300);
  }

  advancerun(event) {
    // Rails.fire(document.querySelector('.form'), 'submit'); // Ensure autosave is complete

    event.preventDefault();

    const buttons = document.querySelectorAll(".compose");
    for (const button of buttons) {
      button.disabled = true;
      button.getElementsByClassName("compose-loading")[0].classList.remove("hidden");
      button.getElementsByClassName("compose-pending")[0].classList.add("hidden");
    }

    this.timeout = setTimeout(() => {

      // debugger
      // TODO: Start spinner on button
      this.outputLength = "medium";
      try{
        this.outputLength = document.querySelector('[name="document[output_length]"]:checked').value;
      }catch{}

      console.log("this.idTarget.innerHTML "+ this.idTarget.innerHTML);

      // this.contentTarget.value
      this.stimulate('Document#advancerun',
        '',
        '',
        '',
        '', // TODO this.outputLength
        '',
        this.idTarget.innerHTML,
        this.outputsTarget.value
      );
      //console.log("Advanced Run");
    }, 500) // This delay is just ensuring the autosaves are complete (todo: find a better way to do this)
    Rails.fire(document.querySelector('.form'), 'submit');
  }

}
