import CableReady from 'cable_ready'
import consumer from './consumer'


// let channel
//
// document.addEventListener('turbo:load', () => {
//   if (channel) return
//
//   channel = consumer.subscriptions.create('UsersChannel', {
//     received(data) {
//       console.log('Data received for UsersChannel: ' + data.operations)
//       if (data.cableReady) CableReady.perform(data.operations)
//     },
//
//     connected () {
//       console.log("Users Channel connected");
//       document.addEventListener('reconnect', this.reconnect)
//       document.addEventListener('reload', this.reload)
//
//     },
//
//     reconnect() {
//       console.log("reconnect users");
//       consumer.disconnect()
//       consumer.connect()
//     },
//
//     reload() {
//       console.log("reload")
//       location.reload();
//     }
//   })
// })

consumer.subscriptions.create('UsersChannel', {
  received(data) {
    console.log('Data received for UsersChannel: ' + data.operations)
    if (data.cableReady) CableReady.perform(data.operations)
  },

  connected () {
    // console.log("Users Channel connected");
    document.addEventListener('reconnect', this.reconnect)
    document.addEventListener('reloadframe', this.reloadframe)

  },

  reconnect() {
    console.log("reconnect users");
    consumer.disconnect()
    consumer.connect()
  },

  reloadframe() {
    console.log("reload")
    document.removeEventListener('reloadframe', this.reloadframe)

    location.reload();
  }
})
