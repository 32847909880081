import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    StimulusReflex.register(this)

    $('.tags').select2({
      tags: true,
      tokenSeparators: [',', ' '],
      createTag: function (params) {
        var term = $.trim(params.term);

        if (term === '') {
          return null;
        }

        return {
          id: term,
          text: term,
          newTag: true // add additional parameters
        }
      }
    });
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted (element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex (element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
  }

  finalizeReflex (element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }

  markdownHandler(){
    this.matches = [
      {
        name: 'header',
        pattern: /^(#){1,6}\s/g,
        action: (text, pattern, lineStartIndex) => {
          const match = pattern.exec(text);
          if (!match) return;
          const size = match[0].length;

          this.quill.formatLine(lineStartIndex, text.length, 'header', size - 1);
          this.quill.deleteText(lineStartIndex, size)
        }
      },
      {
        name: 'blockquote',
        pattern: /^(>)\s/g,
        action: (text, pattern, lineStartIndex) => {
          this.quill.formatLine(lineStartIndex, 1, 'blockquote', true);
          this.quill.deleteText(lineStartIndex, 2)
        }
      },
      {
        name: 'code-block',
        multiline: true,
        pattern: /\`{3}/g,
        action: (startIndex, endIndex) => {
          console.log("found code block "+startIndex);
          console.log("endIndex "+ endIndex);
          this.quill.formatText(startIndex + 4, endIndex - (startIndex + 4), 'code-block', true);
          this.quill.deleteText(startIndex, 4);
          this.quill.deleteText(endIndex - 4, 4);
        }
      },
      {
        name: 'bolditalic',
        pattern: /(?:\*|_){3}(.+?)(?:\*|_){3}/g,
        action: (text, pattern, lineStartIndex) => {
          const match = pattern.exec(text);

          const annotatedText = match[0];
          const matchedText = match[1];
          const startIndex = lineStartIndex + match.index;

          if (text.match(/^([*_ \n]+)$/g)) return;

          this.quill.deleteText(startIndex, annotatedText.length);
          this.quill.insertText(startIndex, matchedText, {
            bold: true,
            italic: true
          })
        }
      },
      {
        name: 'bold',
        pattern: /(?:\*|_){2}(.+?)(?:\*|_){2}/g,
        action: (text, pattern, lineStartIndex) => {
          let match = pattern.exec(text);

          const annotatedText = match[0];
          const matchedText = match[1];
          const startIndex = lineStartIndex + match.index;

          if (text.match(/^([*_ \n]+)$/g)) return;

          this.quill.deleteText(startIndex, annotatedText.length);
          this.quill.insertText(startIndex, matchedText, {
            bold: true
          })
        }
      },
      {
        name: 'italic',
        pattern: /(?:\*|_){1}(.+?)(?:\*|_){1}/g,
        action: (text, pattern, lineStartIndex) => {
          let match = pattern.exec(text);

          const annotatedText = match[0];
          const matchedText = match[1];
          const startIndex = lineStartIndex + match.index;

          if (text.match(/^([*_ \n]+)$/g)) return;

          this.quill.deleteText(startIndex, annotatedText.length);
          this.quill.insertText(startIndex, matchedText, {
            italic: true
          })
        }
      },
      {
        name: 'strikethrough',
        pattern: /(?:~~)(.+?)(?:~~)/g,
        action: (text, pattern, lineStartIndex) => {
          let match = pattern.exec(text);

          const annotatedText = match[0];
          const matchedText = match[1];
          const startIndex = lineStartIndex + match.index;

          if (text.match(/^([*_ \n]+)$/g)) return;

          this.quill.deleteText(startIndex, annotatedText.length);
          this.quill.insertText(startIndex, matchedText, {
            strike: true
          })
        }
      },
      {
        name: 'code',
        pattern: /`([^\`\n\r]+)`/g,
        action: (text, pattern, lineStart) => {
          console.log("found code: "+text);
          console.log("lineStart");
          let match = pattern.exec(text);

          const annotatedText = match[0];
          const matchedText = match[1];
          const startIndex = lineStart + match.index;

          if (text.match(/^([*_ \n]+)$/g)) return;

          this.quill.deleteText(startIndex, annotatedText.length);
          this.quill.insertText(startIndex, matchedText, {
            code: true
          })
        }
      },
      {
        name: 'hr',
        pattern: /^([-*]\s?){3}/g,
        action: (text, pattern, lineStart) => {
          this.quill.deleteText(lineStart, text.length);
          this.quill.insertEmbed(lineStart + 1, 'hr', true, Quill.sources.USER);
          this.quill.insertText(lineStart + 2, "\n", Quill.sources.SILENT);
        }
      },
      {
        name: 'asterisk-ul',
        pattern: /^\s*[\*|\+|-]\s/g,
        action: (text, pattern, lineStart) => {
          this.quill.formatLine(lineStart, 1, 'list', 'unordered');
          this.quill.deleteText(lineStart, 2)
        }
      },
      {
        name: 'image',
        pattern: /(?:!\[(.+?)\])(?:\((.+?)\))/g,
        action: (text, pattern, lineStart) => {
          const startIndex = text.search(pattern);
          const matchedText = text.match(pattern)[0];
          const hrefLink = text.match(/(?:\((.*?)\))/g)[0];
          if (startIndex !== -1) {
            this.quill.deleteText(lineStart, matchedText.length);
            this.quill.insertEmbed(lineStart, 'image', hrefLink.slice(1, hrefLink.length - 1))
          }
        }
      },
      {
        name: 'link',
        pattern: /(?:\[(.+?)\])(?:\((.+?)\))/g,
        action: (text, pattern, lineStart) => {
          const startIndex = text.search(pattern);
          const matchedText = text.match(pattern)[0];
          const hrefText = text.match(/(?:\[(.*?)\])/g)[0];
          const hrefLink = text.match(/(?:\((.*?)\))/g)[0];
          if (startIndex !== -1) {
            this.quill.deleteText(lineStart, matchedText.length);
            this.quill.insertText(lineStart, hrefText.slice(1, hrefText.length - 1), 'link', hrefLink.slice(1, hrefLink.length - 1))
          }
        }
      }
    ]

    console.log("parsing for markdown");
    if (this.length === 0) return;

    const lines = this.quill.getLines(this.index, this.length);

    lines.forEach((line, index) => {
      let lineText = line.domNode.textContent;
      const lineIndex = this.quill.getIndex(line);

      // console.log("lineText: "+ lineText);

      let oldLineText = null;
      while (oldLineText !== lineText) {
        oldLineText = lineText;

        for (let match of this.matches) {
          // console.log("match.pattern "+match.pattern);
          const matchedText = lineText.match(match.pattern);

          if (matchedText) {
            // NOTE: `code-block` is a special case (multi-line)
            console.log("matchedText "+ matchedText);
            if (match.name === 'code-block') {
              if (index + 1 === lines.length - 1) {
                continue;
              }

              const restOfLines = lines.slice(index + 1, lines.length);
              const lastIndex = restOfLines.findIndex(l => l.domNode.textContent.match(match.pattern));
              if (lastIndex === -1) {
                continue;
              }

              match.action(this.quill.getIndex(line), this.quill.getIndex(restOfLines[lastIndex]));
              lines.splice(index, lastIndex + 1);

              break;
            } else {
              match.action(lineText, match.pattern, lineIndex);

              let updatedLine = this.quill.getLine(lineIndex)[0];
              lineText = updatedLine.domNode.textContent;
              break;
            }
          }
        }
      }
    })
  }

}
