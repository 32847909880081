// 🚫 DEFAULT RAILS INCLUDES
// This section represents the default includes for a Rails application. Bullet Train's includes and your own
// includes should be specified at the end of the file, not in this section. This helps avoid merge conflicts in the
// future should the framework defaults change.

import "./support/jquery";
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
import "./controllers"
import "./channels"


// Rails.start()
ActiveStorage.start()

// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default settings for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.

import "./support/jstz";
import "./support/turn";

import "@bullet-train/bullet-train"
import "@bullet-train/bullet-train-sortable"

require("@icon/themify-icons/themify-icons.css")
require("@fortawesome/fontawesome-pro/css/all.css")

import { trixEditor } from "@bullet-train/fields"
trixEditor()

import "./electron"

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.
import TurboReady from 'turbo_ready' // <- import first

TurboReady.initialize(Turbo.StreamActions) // then Add TurboReady stream actions to Turbo

import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
import Toolkit from '@alpine-collective/toolkit'

Alpine.plugin(Toolkit)

window.Alpine = Alpine

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

// TODO causing perf issues
// function enableAlpine(options, fromEl, toEl) {
//   debounce(1000, function(options, fromEl, toEl){
//     Alpine.start()
//     console.log("Alpine started ");
//
//     if (fromEl.__x) { window.Alpine.clone(fromEl.__x, toEl) }
//     return true
//   });
// }


document.addEventListener("DOMContentLoaded", function(event) {
  window.Alpine.start();
  window.alpine_enabled = true;
  console.log("Alpine started ");
  $('form[data-persist]').garlic();
});


// import Quill from 'quill';
// import QuillCursors from 'quill-cursors';

// require quill.global
// import "./aibot"
import mixitup from 'mixitup';
