import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textInput", "recordButton"];

  connect() {
    if (!("webkitSpeechRecognition" in window)) {
      this.recordButtonTarget.classList.add("hidden");
    } else {
      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = false;
      this.recognition.interimResults = true;

      this.recognition.addEventListener("start", () => this.onStart());
      this.recognition.addEventListener("result", (event) =>
        this.onResult(event)
      );
      this.recognition.addEventListener("error", (event) => this.onError(event));
      this.recognition.addEventListener("end", () => this.onEnd());
    }
  }

  toggleRecognition() {
    if (this.recognition.recognizing) {
      this.recognition.stop();
    } else {
      this.recognition.start();
    }
  }

  onStart() {
    this.recognition.recognizing = true;
    // this.recordButtonTarget.textContent = "🛑";
    this.recordButtonTarget.querySelector("i").classList.replace("fa-microphone", "fa-stop");
  }

  onResult(event) {
    let transcript = "";
  
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        transcript += event.results[i][0].transcript;
      }
    }
  
    // If there's existing text in the input field, add a space before appending the new transcript
    if (this.textInputTarget.value && transcript) {
      this.textInputTarget.value += " ";
    }
  
    this.textInputTarget.value += transcript; // Append the most recent recognized speech to the existing text
  }
  

  onError(event) {
    console.error("Speech recognition error:", event.error);
  }

  onEnd() {
    this.recognition.recognizing = false;
    this.recordButtonTarget.querySelector("i").classList.replace("fa-stop", "fa-microphone");
  }
}
