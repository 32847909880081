import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = [ "description", "style", "artist", "medium", "mood", "keywords", "projectid", "subject" ];

  connect() {
    StimulusReflex.register(this);
    console.log("art js attached");
    document.addEventListener('processart', this.processContent);
    document.addEventListener('addwaiting', this.processWaiting);

  }

  disconnect () {
    document.removeEventListener('processart', this.processContent)
    document.removeEventListener('addwaiting', this.processWaiting);

  }

  create(event) {
    event.preventDefault()

    try{
      const buttons = document.querySelectorAll(".compose");
      for (const button of buttons) {
        button.disabled = true;
        button.getElementsByClassName("compose-loading")[0].classList.remove("hidden");
        if(button.getElementsByClassName("compose-pending")[0] != undefined){
          button.getElementsByClassName("compose-pending")[0].classList.add("hidden");
        }
      }
    }catch{}

    // get our fields
    this.style = "";
    try{
      this.style = $("[id='art_style']").select2('data').map(function(k,i){ return k["id"] }).toString();
    }catch{}

    this.artist = "";
    try{
      this.artist = $("[id='art_artist']").select2('data').map(function(k,i){ return k["id"] }).toString();
    }catch{}

    this.medium = "";
    try{
      this.medium = $("[id='art_medium']").select2('data').map(function(k,i){ return k["id"] }).toString();
    }catch{}

    this.mood = "";
    try{
      this.mood = $("[id='art_mood']").select2('data').map(function(k,i){ return k["id"] }).toString();
    }catch{}

    this.keywords = "";
    try{
      this.keywords = $("[id='art_keywords']").select2('data').map(function(k,i){ return k["id"] }).toString();
    }catch{}

    this.subject = "";
    try{
      this.subject = $('[name="art[arts_subject]"]').val();
    }catch{}

    // this.contentTarget.value
    this.stimulate('Art#create',
      this.descriptionTarget.value,
      this.style,
      this.artist,
      this.medium,
      this.mood,
      this.keywords,
      this.projectidTarget.innerHTML,
      this.subject

    );
    // console.log("Advanced");

    // for (const button of buttons) {
    //   button.disabled = false;
    //   button.getElementsByClassName("compose-loading")[0].classList.add("hidden");
    //   button.getElementsByClassName("compose-pending")[0].classList.remove("hidden");
    // }
  }

  processWaiting = event => {
    console.log("processWaiting");
    setTimeout(function(){
      if(document.querySelector(".waiting") !== null){
        document.querySelector(".waiting").classList.remove("hidden");
        document.querySelector(".waiting").classList.remove("!hidden");
        if(document.querySelector('.scroll-place-holder') !== null){
          document.querySelector('.scroll-place-holder').scrollIntoView({block: "end",  behavior: 'smooth'});
        }
      }
    },500)
  }

  processContent = event => {
    const { content, url } = event.detail
    console.log(`Triggering aiArt ${content} ${url}`);
    if(document.querySelector(`#art_container`) !== null){
      document.querySelector(`#art_container`).style.display = 'block';
    } 

    if(document.querySelector(`#art_images`) !== null){
      if(document.querySelector(".waiting") !== null){
        document.querySelector(".waiting").classList.remove("hidden");
        document.querySelector(".waiting").classList.remove("!hidden");
      }

      document.querySelector(`#art_images`).style.display = 'block';
      document.querySelector(`.${content} .compose-loading`).classList.remove("hidden");
      document.querySelector(`.${content} .image_preview`).src = url;
      document.querySelector(`.${content}_button`).href = url;
      document.querySelector(`.${content} .image_preview`).classList.remove("hidden");
      document.querySelector('#art_images').scrollIntoView();

      if(document.querySelector('.scroll-place-holder') !== null){
        document.querySelector('.scroll-place-holder').scrollIntoView({block: "end",  behavior: 'smooth'});
      }

      // reactivate button if all processed
      if($(".image_preview.hidden").length == 0 ){
        [].forEach.call(document.querySelectorAll(`.compose-loading`), function(div) {
          div.classList.add("hidden")
        });

        [].forEach.call(document.querySelectorAll(`.compose-pending`), function(div) {
          div.classList.remove("hidden")
        });

        [].forEach.call(document.querySelectorAll(`.compose`), function(div) {
          div.removeAttribute("disabled")
        });
        document.querySelector(`#art_images`).id = ''; // No longer focused
        if(document.querySelector(".waiting") !== null){
          document.querySelector(".waiting").classList.add("hidden");
        }
        if(document.querySelector(`#art_container`) !== null){
          document.querySelector(`#art_container`).style.display = 'hidden';
        } 
        
        if(document.querySelector('.scroll-place-holder') !== null){
          document.querySelector('.scroll-place-holder').scrollIntoView({block: "end",  behavior: 'smooth'});
        }
      }

    }
  }
}
