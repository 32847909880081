import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default class extends Controller {
  static values = {
    content: String,
    placement: { type: String, default: 'bottom' },
    otherOptions: { type: Object, default: {} },
    minCharCount: { type: String, default: '0' },
  }

  connect() {
    this.initTippy()
  }

  initTippy() {
    const minContentLength = this.minCharCountValue
    const charCount = this.contentValue.length

    return charCount > minContentLength ? this.setTippy() : false
  }

  setTippy() {
    tippy(this.element, {
      content: this.contentValue,
      placement: this.placementValue,
      ...this.otherOptionsValue,
    })
  }
}
