import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useClickOutside(this);
  }

  clickOutside(event) {
    event.preventDefault();
    this.close(event);
  }

  close(event) {
    event.preventDefault();
    $('.side_panel').hide();
    document.getElementById('side_panel').removeAttribute('src')
    $('.modal').hide();
    document.getElementById('modal').removeAttribute('src')
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->panel#submitEnd"
  submitEnd(e) {
    console.log("submitEnd "+e);
    if (e.detail.success) {
      this.close()
    }
  }
}
