import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = [ "id", "input", "command" ]
  connect() {
    StimulusReflex.register(this);

    const afterMorphHandler = event => {
      focusChat();
    }
    document.addEventListener('cable-ready:after-update', afterMorphHandler)
  }

  sendAction(event){
    event.preventDefault()
    console.log($(event.target));
    let text = $(event.target).text();

    if(text == ""){
      text = $(event.target).parent().text();
    }

    text = text.trim();
    console.log("Text: " + text);
    let chatbot_run_id = $(".chatbot_run_id").text();



    try{
      chatbot_run_id = this.idTarget.innerHTML;
    }
    catch(err){
      console.log("Error: " + err);
    }

    $('<div class="chatmsg chat chat-end"><div class="chat-bubble align-middle bg-primary-600 text-white">'+ text +'</div></div>').insertBefore(".waiting");

    document.querySelector(".waiting").classList.remove("hidden");
    document.querySelector(".waiting").classList.remove("!hidden");

    //document.querySelector('.scroll-place-holder').scrollIntoView({block: "end"});

    this.stimulate('Chatbot#advancechat',
      chatbot_run_id,
      text
    );

    document.getElementById("inputbox").value = "";

    focusChat();
  }

  send(event){
    event.preventDefault();

    let chatbot_run_id = $(".chatbot_run_id").text();

    try{
      chatbot_run_id = this.idTarget.innerHTML;
    }
    catch(err){
      console.log("Error: " + err);
    }

    // add msg
    $('<div class="chatmsg chat chat-end"><div class="chat-bubble align-middle bg-primary-600 text-white">'+ $(this.inputTarget).val() +'</div></div>').insertBefore(".waiting");

    document.querySelector(".waiting").classList.remove("hidden");
    document.querySelector(".waiting").classList.remove("!hidden");


    //document.querySelector('.scroll-place-holder').scrollIntoView({block: "end"});

    this.stimulate('Chatbot#advancechat',
      chatbot_run_id,
      $(this.inputTarget).val()
    );

    document.getElementById("inputbox").value = "";

    focusChat();

  }
}
