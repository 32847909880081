import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

// import Hotkeys from 'stimulus-hotkeys'
// import hotkeys from 'hotkeys-js'
import { identifierForContextKey } from "stimulus/webpack-helpers"
import { controllerDefinitions as bulletTrainControllers } from "@bullet-train/bullet-train"
import { controllerDefinitions as bulletTrainFieldControllers } from "@bullet-train/fields"
import { controllerDefinitions as bulletTrainSortableControllers } from "@bullet-train/bullet-train-sortable"
import RevealController from 'stimulus-reveal'
import CableReady from 'cable_ready'
import consumer from '../channels/consumer'

const application = Application.start()

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { context as controllersContext } from './**/*_controller.js';
import StimulusReflex from 'stimulus_reflex'
import controller from '../controllers/application_controller'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

application.load(bulletTrainControllers)
application.load(bulletTrainFieldControllers)
application.load(bulletTrainSortableControllers)

application.register('reveal', RevealController)

const controllers = Object.keys(controllersContext).map((filename) => ({
  identifier: identifierForContextKey(filename),
  controllerConstructor: controllersContext[filename] }))

application.load(controllers)

CableReady.initialize({ consumer })
StimulusReflex.initialize(application, { consumer, controller, isolate: true })
// StimulusReflex.debug = process.env.RAILS_ENV === 'development'
application.consumer = consumer

// application.register('hotkeys', Hotkeys)
application.register('textarea-autogrow', TextareaAutogrow)

// Auto save ability
// Import and register all TailwindCSS Components
import { Alert, Dropdown, Modal, Tabs, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
// application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
// application.register('tabs', Tabs)
import Popover from 'stimulus-popover'
application.register('popover', Popover)

application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Timeago from 'stimulus-timeago'
application.register('timeago', Timeago)
import Clipboard from 'stimulus-clipboard'
application.register('clipboard', Clipboard)

// console.log("Index.js finished")
// import * as Grammarly from "@grammarly/editor-sdk";
// Grammarly.init("client_5DFuHYMfk4ocq75Enb83yg");

import Rails from "@rails/ujs";

window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) {
    Rails.start();
}
